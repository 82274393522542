import { useEffect } from "react";
import { useRouter } from "next/router";
import { usePrivy } from "@privy-io/react-auth";

const RequireNoAuthorization = ({ children }: { children: JSX.Element }) => {
    const { ready, authenticated } = usePrivy();
    const router = useRouter();

    useEffect(() => {
        if (ready && authenticated) {
            router.push("/");
        }
    }, [ready, authenticated, router]);

    return children;
};

export default RequireNoAuthorization;
