import React from "react";
import { TelegramLoader } from "../components/loaders/TelegramLoader";
import { AppShell, Flex, Group, useMantineTheme } from "@mantine/core";
import RequireNoAuthorization from "../components/require/RequireNoAuthorization";

const LayoutWrapper = ({
    children,
    ...props
}: {
    children: JSX.Element;
}) => {
    const theme = useMantineTheme();
    return (
        <RequireNoAuthorization>
            <TelegramLoader>
                <Flex justify="center" w="100%">
                    <AppShell
                        header={{ height: 50 }}
                        footer={{ height: 60 }}
                        maw={theme.breakpoints.lg}
                        >
                        <AppShell.Header bd="0" maw={theme.breakpoints.lg} ml="auto" mr="auto" px="md">
                           
                        </AppShell.Header>
                        <AppShell.Main px="md">
                            {children}
                        </AppShell.Main>
                        <AppShell.Footer px="md" bd="0" maw={theme.breakpoints.lg} ml="auto" mr="auto">
                            
                        </AppShell.Footer>
                    </AppShell>
                </Flex>
            </TelegramLoader>
        </RequireNoAuthorization>
    );
};

export const getWithoutAuthLayout = (page: JSX.Element) => (
    <LayoutWrapper>{page}</LayoutWrapper>
);
export default LayoutWrapper;
