import { useState } from "react";
import { useRouter } from "next/router";
import { usePrivy, useLogin } from "@privy-io/react-auth";
import { Button, Flex } from "@mantine/core";
import { getWithoutAuthLayout } from "../layouts/withoutAuth";

const Login = () => {
    const router = useRouter();
    const { authenticated } = usePrivy();
    const [isLoading, setIsLoading] = useState(false);
    const { login } = useLogin();

    const handleLogin = async () => {
        setIsLoading(true);
        try {
            login();
            router.replace("/");
        } catch (error) {
            console.error("Login failed:", error);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Flex
            ta="center"
            justify="center"
            align="center"
            h="calc(100vh - 110px)"
        >
            {!authenticated && <Button
                onClick={handleLogin} loading={isLoading}
            >
                Log in
            </Button>}
        </Flex>
    );
};

Login.getLayout = getWithoutAuthLayout;
export default Login;